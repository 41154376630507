import {
    ViewEncapsulation,
    Component,
    HostBinding,
    Input,
    EventEmitter,
    Output,
} from '@angular/core';

@Component({
    selector: 'bazis-accordion',
    templateUrl: './accordion.component.html',
    styleUrls: ['accordion.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class AccordionElement {
    // Можно задать первоночальное состояние аккордиона
    @Input() isOpen: boolean = true;

    // Наличие иконки сворачивания
    @Input() hasAngleIcon: boolean = true;

    @Input() type: 'underline' | 'clear' | 'outline' | string;

    @Input() collapsable: boolean = true;

    @Input() iconUp = 'angle-small-up';

    @Input() iconDown = 'angle-small-down';

    @Input() iconSize = 'l';

    @Input() allowBubbling = true;

    @Output() isOpenChange: EventEmitter<boolean> = new EventEmitter();

    @HostBinding('class') get accordionClass() {
        return {
            ['bazis-accordion--opened']: this.isOpen,
            [`bazis-accordion_${this.type}`]: !!this.type,
            ['bazis-accordion_no-collapsable']: !this.collapsable,
        };
    }

    constructor() {}

    toggle(ev) {
        if (!this.collapsable) return;
        if (!this.allowBubbling) {
            ev.stopPropagation();
            ev.preventDefault();
        }
        this.isOpen = !this.isOpen;
        this.isOpenChange.emit(this.isOpen);
    }
}
