import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'bazis-map-fullscreen-control',
    templateUrl: 'fullscreen.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BazisFullscreenComponent {
    @Input() isFullscreen: boolean;

    @Output()
    toggle = new EventEmitter();

    emitToggle() {
        this.toggle.emit();
    }
}
