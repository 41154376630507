<ng-container *transloco="let t">
    <div #btnFullscreen>
        <bazis-button
            fill="outline"
            (click)="emitToggle()"
        >
            <bazis-icon
                slot="icon-only"
                [name]="isFullscreen ? 'compress' : 'expand'"
            ></bazis-icon>
        </bazis-button>
        <bazis-tooltip
            [reference]="btnFullscreen"
            placement="left"
            class="bh-popper-z-index"
            [isStatic]="true"
            [offset]="[-12, 0]"
            [arrow]="false"
        >
            {{
                t(
                    isFullscreen
                        ? 'tooltip.fullscreen.compress'
                        : 'tooltip.fullscreen.expand'
                )
            }}
        </bazis-tooltip>
    </div>
</ng-container>
