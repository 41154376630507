<div
    class="bazis-map"
    #mapElement
    [class.bazis-map_grayscale]="tileMode === 'grey'"
></div>
<ng-container
    *ngIf="{
        settings: settings$ | async,
        resized: resized$ | async,
        layers: layers$ | async,
        map: map$ | async,
        mapSelected: mapSelected$ | async,
        centerOnCoordinates: centerOnCoordinates$ | async,
        actions: popupActions$ | async,
        select: select$ | async,
        dragged: dragged$ | async
    } as data"
>
    <ng-container *ngIf="data.map">
        <div class="bazis-map__base-control bazis-map__base-control_top">
            <ng-content select="[top]"></ng-content>
        </div>
        <div class="bazis-map__base-control">
            <bazis-map-fullscreen-control
                *ngIf="fullscreenControlEnabled"
                [isFullscreen]="isFullscreen$.value"
                (toggle)="toggleFullscreen()"
            ></bazis-map-fullscreen-control>
            <bazis-map-open-modal-control
                *ngIf="openModalControlEnabled"
                [mapSettings$]="settings$"
                [mapLayers$]="layers$"
                [mapPopupInfo$]="popupInfo$"
            ></bazis-map-open-modal-control>

            <bazis-map-tiles-control
                *ngIf="tileControlEnabled && data.settings.tiles?.length > 1"
                [tiles]="data.settings.tiles"
                [selectedTileId]="selectedTileId"
                (setTileLayer)="setTileLayer($event)"
            ></bazis-map-tiles-control>

            <bazis-map-zoom-control
                *ngIf="zoomControlEnabled"
                [map]="data.map"
            ></bazis-map-zoom-control>

            <bazis-map-my-location-control
                *ngIf="myLocationEnabled"
                [map]="data.map"
            ></bazis-map-my-location-control>
        </div>
    </ng-container>
</ng-container>

<ng-container *ngIf="executeFitLayersBounds$ | async"></ng-container>
