import {
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
} from '@angular/core';
import {
    BehaviorSubject,
    combineLatest,
    merge,
    Observable,
    of,
    shareReplay,
    withLatestFrom,
} from 'rxjs';
import { BazisEntityService } from '@bazis/shared/services/entity.service';
import {
    catchError,
    combineLatestWith,
    debounceTime,
    filter,
    map,
    startWith,
    switchMap,
    tap,
} from 'rxjs/operators';
import { EntData, SearchSettings, SimpleData } from '@bazis/shared/models/srv.types';
import { DEFAULT_LIST_LIMIT, SHARE_REPLAY_SETTINGS } from '@bazis/configuration.service';
import { ScrollDirective } from '@bazis/shared/directives/scroll.directive';
import { CommonModule } from '@angular/common';
import { TranslocoPipe } from '@jsverse/transloco';
import { ColorDirective } from '@bazis/shared/directives/color.directive';

@Component({
    selector: 'bazis-infinite-list',
    templateUrl: './infinite-list.component.html',
    standalone: true,
    imports: [ColorDirective, ScrollDirective, CommonModule, TranslocoPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfiniteListComponent implements OnDestroy, OnInit {
    @HostBinding('class') get hostStyle() {
        return {
            'bazis-list': true,
            'bazis-list_control-dd': true,
        };
    }

    @Input() staticOptionsEntities: EntData[] = [];

    @Input() searchSettings: SearchSettings;

    @Input() search$: Observable<string>;

    @Input() limit = DEFAULT_LIST_LIMIT;

    @Input() searchDebounce: number = 200;

    @Input() emptyValue;

    @Input() emptyListKey = 'title.emptyList';

    @Input() excludeIds$: Observable<string[]> = new BehaviorSubject([]);

    @ContentChild(TemplateRef) itemTpl: TemplateRef<any>;

    list = [];

    isLoading = false;

    needNextPage$ = new BehaviorSubject(false);

    listAll$: Observable<any[]>;

    list$: Observable<any[]>;

    countListAll: number;

    countPortionLoad: number;

    currentPortionLoad: number;

    searchUpdated$: Observable<string>;

    constructor(protected entityService: BazisEntityService) {}

    ngOnInit(): void {
        if (!this.excludeIds$) this.excludeIds$ = new BehaviorSubject([]);
        this.list = this.generateInitialList();
        if (!this.search$) {
            this.search$ = new BehaviorSubject('');
        }

        if (this.searchSettings?.limit) this.limit = this.searchSettings.limit;

        this.searchUpdated$ = this.search$.pipe(
            startWith(''),
            tap(() => {
                this.isLoading = false;
                this.list = this.generateInitialList();
            }),
            shareReplay(SHARE_REPLAY_SETTINGS),
        );

        this.listAll$ = merge(this.needNextPage$, this.searchUpdated$).pipe(
            filter(() => this.searchSettings && !this.isLoading),
            combineLatestWith(this.searchUpdated$),
            debounceTime(this.searchDebounce),
            switchMap(([init, search]) => {
                this.isLoading = true;

                const params = this.searchSettings.paramsGenerator
                    ? this.searchSettings.paramsGenerator(search)
                    : this.searchSettings.params;

                search = this.searchSettings.paramsGenerator ? '' : search;

                return this.entityService
                    .getEntityList$(this.searchSettings.entityType, {
                        suffix: this.searchSettings.suffix || '',
                        search: search || '',
                        params: params || {},
                        offset: this.emptyValue ? this.list.length - 1 : this.list.length,
                        limit: this.limit,
                    })
                    .pipe(catchError((e) => of({ list: [], $meta: { pagination: { count: 0 } } })));
            }),
            tap((response) => {
                this.countListAll = response.$meta?.pagination.count;
                this.list = this.list.concat(response.list);
                this.isLoading = false;
            }),
            map(() => this.list),
            shareReplay(SHARE_REPLAY_SETTINGS),
        );

        this.list$ = combineLatest([this.listAll$, this.excludeIds$]).pipe(
            withLatestFrom(this.search$.pipe(startWith(null))),
            debounceTime(0),
            map(([[list, excludeIds], search]) => {
                this.countPortionLoad = Math.ceil(this.countListAll / this.limit);
                this.currentPortionLoad = Math.ceil(list.length / this.limit);

                const staticEntities = this.staticOptionsEntities || [];

                if (!excludeIds || excludeIds.length === 0)
                    return search ? list : staticEntities.concat(list);

                const staticResults = staticEntities.filter((v) => excludeIds.indexOf(v.id) === -1);
                const listResults = list.filter((v) => excludeIds.indexOf(v.id) === -1);

                return search ? listResults : staticResults.concat(listResults);
            }),
            shareReplay(SHARE_REPLAY_SETTINGS),
        );
        this.needNextPage$.next(true);
    }

    generateInitialList() {
        return this.emptyValue ? [this.emptyValue] : [];
    }

    ngOnDestroy(): void {}

    trackById(index, item) {
        return item.id;
    }
}
