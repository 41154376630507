import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Self,
    TemplateRef,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { BazisEntityService } from '@bazis/shared/services/entity.service';
import { EntityFormControl } from '@bazis/form/models/form.types';
import { EntData, SearchSettings, SimpleData } from '@bazis/shared/models/srv.types';
import { TemplateObservable } from '@bazis/shared/classes/template-observable';
import { BazisFormService } from '@bazis/form/services/form.service';
import { Observable, shareReplay } from 'rxjs';
import { TooltipSettings } from '@bazis/form/models/form-element.types';
import { filter, map } from 'rxjs/operators';
import { SHARE_REPLAY_SETTINGS } from '@bazis/configuration.service';
import { latinizeStr } from '@bazis/utils';
import { BazisMediaQueryService } from '@bazis/shared/services/media-query.service';

@Component({
    selector: 'bazis-input-search',
    templateUrl: './input-search.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BazisInputSearchComponent implements ControlValueAccessor, OnInit, OnDestroy {
    @Input() entityType: string = null;

    @Input() searchSettings: SearchSettings = null;

    // TODO: include in default template/logic
    @Input() staticOptions: SimpleData[];

    @Input() infiniteListTpl: TemplateRef<any>;

    @Output() touched = new EventEmitter();

    // знак вопроса может быть рядом с полем
    @Input() tooltipKey: string = null;

    // заголовок поля
    @Input() titleKey: string = null;

    // заголовок поля
    @Input() title: string = null;

    // input's placeholder
    @Input() placeholderKey: string = null;

    // возможно, нужен будет какой-то коммент под полем
    @Input() noteKey: string = null;

    // параметры для tooltip
    @Input() tooltipParams: any = null;

    // параметры для title
    @Input() titleParams: any = null;

    // отображение метки поля выделенным стилем
    @Input() hasTitleMajor: boolean = false;

    // параметры для note
    @Input() noteParams: any = null;

    // иконка, которую надо вывести справа
    @Input() rightIcon: string = 'menu-burger';

    @Input() emptyValue: SimpleData = null;

    @Input() multiple: boolean = false;

    @Input() nameField: string = null; // use value from item.$snapshot[nameField] to display item in the list

    @Input() mapSettings: boolean = false;

    // настройки отображения и работы тултипа
    @Input() tooltipSettings: TooltipSettings = null;

    @Input() mapLayers: boolean = false;

    @Input() required$: Observable<boolean> = null;

    @Input() excludeIds$: Observable<string[]> = null;

    @Input() forceLatin: boolean = false; // force latin symbols in input field

    // наличие стиралки для поля
    @Input() hasEraser: boolean = true;

    searchControl: FormControl<string | null> = new FormControl();

    public onChange = (_: any) => {};

    public onTouched = () => {};

    isTouched = false;

    emptyValueEntity = null;

    protected _isInited = false;

    values: TemplateObservable<string[]> = new TemplateObservable<string[]>([]);

    valuesMap: Map<any, boolean> = new Map([]);

    isDisabled = false;

    showList: boolean = false;

    isFocused = false;

    get staticOptionsEntities() {
        return this.staticOptions
            ? this.staticOptions.map((v) => this.entityService.toEntityItem(v))
            : [];
    }

    get staticOptionsEntitiesMap() {
        return this.staticOptionsEntities.reduce(
            (acc, current) => ({ ...acc, [current.id]: current }),
            {},
        );
    }

    searchValue$ = this.searchControl.valueChanges.pipe(
        map((value) => {
            if (this.forceLatin) {
                let newValue = latinizeStr(value.toUpperCase());
                if (value !== newValue) {
                    this.searchControl.setValue(newValue);
                    return undefined;
                }
            }
            return value;
        }),
        filter((v) => v !== undefined),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    constructor(
        @Self() public ngControl: NgControl,
        protected entityService: BazisEntityService,
        public mqSrv: BazisMediaQueryService,
    ) {
        ngControl.valueAccessor = this;
    }

    ngOnInit() {
        if (this.ngControl.control instanceof EntityFormControl) {
            BazisFormService.getPropertiesFromConfig(this, this.ngControl.control.$config);
        }

        if (this.emptyValue) {
            this.emptyValueEntity = this.entityService.toEntityItem(this.emptyValue);
        }

        if ((!this.searchSettings || !this.searchSettings.entityType) && this.entityType) {
            this.searchSettings = {
                ...this.searchSettings,
                entityType: this.entityType,
            };
        }

        if (!this.entityType && this.searchSettings.entityType) {
            this.entityType = this.searchSettings.entityType;
        }

        this._isInited = true;
        this.writeValue(this.ngControl.control.value);
    }

    ngOnDestroy(): void {
        this.valuesMap.clear();
    }

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
        if (isDisabled) this.searchControl.disable();
        else this.searchControl.enable();
    }

    public writeValue(value): void {
        if (!this._isInited) return;
        this.searchControl.setValue('');
        // может быть multiple === true, но value === null. Необходимо избежать появления [null]
        this.values.set(
            this.multiple && Array.isArray(value) ? value : !this.multiple ? [value] : [],
        );
        this.valuesMap.clear();
        if (this.multiple && Array.isArray(value)) {
            value.forEach((item) => this.valuesMap.set(item, true));
        } else if (!this.multiple) {
            this.valuesMap.set(value, true);
        }
    }

    public markAsTouched() {
        if (!this.isTouched) {
            this.onTouched();
            this.isTouched = true;
            this.touched.emit();
        }
    }

    toggleValue(id: string = '') {
        let value = this.values._;

        if (!id) {
            value = [];
        } else {
            if (value.indexOf(id) > -1) {
                value = value.filter((v) => v !== id);
                this.valuesMap.delete(id);
            } else {
                if (this.multiple) {
                    value.push(id);
                } else {
                    this.valuesMap.clear();
                    value = [id];
                }

                this.valuesMap.set(id, true);
            }
        }

        this.onChange(this.multiple ? [...value] : value[0] || null);
        this.values.set(value);

        this.markAsTouched();

        if (!this.multiple) {
            this.hideList();
        }
    }

    toggleList() {
        if (this.showList && this.isFocused) return;
        this.showList = !this.showList;
        this.markAsTouched();
    }

    hideList() {
        if (!this.isFocused) this.showList = false;
    }

    // Откроет модалку для множественного выбора (список с чекбоксами)
    openModalList() {
        console.log('open modal list');
    }
}
