<ng-container *ngIf="href">
    <a
        part="link"
        [attr.href]="href"
        [attr.target]="target"
        [attr.download]="download"
        [class.bh-events-none]="disabled"
    >
        <ng-container [ngTemplateOutlet]="innerTpl"></ng-container>
    </a>
</ng-container>
<ng-container *ngIf="routerLink || bindRouterLink">
    <a
        part="link"
        [routerLink]="routerLink || bindRouterLink"
        [queryParams]="queryParams"
        [fragment]="fragment"
        [target]="target"
        [class.bh-events-none]="disabled"
    >
        <ng-container [ngTemplateOutlet]="innerTpl"></ng-container>
    </a>
</ng-container>
<ng-container *ngIf="!href && !routerLink && !bindRouterLink">
    <span [class.bh-events-none]="disabled">
        <ng-container [ngTemplateOutlet]="innerTpl"></ng-container>
    </span>
</ng-container>

<ng-template #innerTpl>
    <slot name="start"></slot>
    <span class="bazis-link__text">
        <slot></slot>
    </span>
    <slot name="end"></slot>
</ng-template>
