import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    Renderer2,
    Self,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { BazisInputSearchComponent } from '@bazis/form/components/input-search.component';
import { BazisEntityService } from '@bazis/shared/services/entity.service';
import { BazisMediaQueryService } from '@bazis/shared/services/media-query.service';

@Component({
    selector: 'bazis-select-search',
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BazisSelectSearchComponent extends BazisInputSearchComponent {
    @ViewChild('searchInput') set searchInput(el: ElementRef) {
        if (!el) return;

        this.nativeSearchInput = el.nativeElement;

        this.renderer.listen(el.nativeElement, 'keydown.tab', (event) => {
            this.hideList();
        });
        // Тонкая настройка: "Смотреть можно, трогать — нет!"
        this.renderer.listen(this.nativeSearchInput, 'focus', (event) => {
            // this.nativeSearchInput.value = this.nativeSearchInput.value;
            // console.log('focus', this.nativeSearchInput.value.length);
            this.nativeSearchInput.selectionStart = this.nativeSearchInput.value.length;
            // console.log('focus', this.nativeSearchInput.value.length, this.nativeSearchInput.selectionStart);
        });
    }

    // иконка, которую надо вывести слева
    @Input() leftIcon: string = null;

    // внешний вид, secondary - серый
    @Input() type: 'default' | 'secondary' = 'default';

    // отображение иконки лупы, вместо птички дропдауна
    @Input() hasSearchIcon: boolean = false;

    @Input() selectedValueTpl: TemplateRef<any>;

    isFocused = false;

    nativeSearchInput;

    constructor(
        @Self() public ngControl: NgControl,
        entityService: BazisEntityService,
        mqSrv: BazisMediaQueryService,
        private renderer: Renderer2,
    ) {
        super(ngControl, entityService, mqSrv);
    }

    onClear() {
        this.values._.forEach((val) => {
            this.toggleValue(val);
        });
        this.ngControl.control.setValue(this.emptyValue || (this.multiple ? [] : null));
    }

    toggleList() {
        if (!this.showList) {
            this.nativeSearchInput.focus();
            // Управление положением курсора
            // this.nativeSearchInput.setSelectionRange(
            //     this.nativeSearchInput.value.length,
            //     this.nativeSearchInput.value.length,
            // );
        }
        super.toggleList();
    }

    toggleValue(val) {
        super.toggleValue(val);
        this.searchControl.reset();
    }
}
